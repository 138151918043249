//展示慕课视频内容组件
<template>
    <div class="study_video">
        <!-- 内容 -->
        <div class="content" v-if="!showLoading">
            <div class="video_list">
                <!-- 左边的列表部分 -->
                <div class="list">
                    <div :class="['item_list', { select_bg: currPos == index }]" v-for="(item, index) in videoList" :key="index" @click.stop="changeVideo(item, index)">
                        <img class="video_img" :src="item.videoCr" />
                        <div class="video_name">{{ item.name }}</div>
                    </div>
                </div>

                <!-- 右边的视频播放器部分 -->
                <div class="player">
                    <!-- 引入视频播放组件 -->
                    <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </div>
            </div>
        </div>
        <!-- 加载框 -->
        <Loading v-if="showLoading"></Loading>
    </div>
</template>
<script>
//引入混入文件
import video from "../../mixin/video";
export default {
    name: "VideoStudy",
    mixins: [video],
    data() {
        return {
            //视频的播放位置
            currPos: 0,
            //左边的视频播放列表
            videoList: [],
            //加载框
            showLoading: true,
        };
    },
    created() {
        //页面数据
        this.httpData();
    },
    mounted() {},
    methods: {
        //获取页面数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 4;
            param.curriculumCenterId = this.curriculumCenterId;
            param.curriculumChapterId = this.curriculumChapterId;
            param.chapterClassId = this.chapterClassId;
            param.sectionId = 2;
            let res = await this.$http.fetchPost(
                this.$api.LESSON_CENTER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //表示请求成功
                this.videoList = res.data.data[0].learningContent;

                //配置视频名称
                this.videoList.forEach((item) => {
                    this.$set(
                        item,
                        "name",
                        this.getNameByVideoPath(item.video)
                    );
                });
                //初始化配置下视频播放器
                this.initVideoPlayerOptions();
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },

        //根绝地址获取视频名称
        getNameByVideoPath(video) {
            //最后一个'/'出现在字符串中的位置
            let start = video.lastIndexOf("/") + 1;
            //获取.mp4出现在字符串中的位置
            let end = video.indexOf(".mp4");
            return video.substring(start, end);
        },

        //配置播放器对象
        initVideoPlayerOptions() {
            this.playerOptions.sources[0].src = this.videoList[0].video;
            this.playerOptions.poster = this.videoList[0].videoCr;
            //动态设置播放器的宽高比
            this.playerOptions.aspectRatio = "100:50";
        },

        //切换视频的方法
        changeVideo(item, index) {
            if (this.currPos != index) {
                this.currPos = index;
                //切换视频源
                //更改封面
                this.playerOptions.poster = item.videoCr;

                this.playerOptions.sources[0].src = item.video;
            }
        },
    },
    computed: {},
    components: {},
    props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
};
</script>
<style lang="scss" scoped>
.study_video {
    background: black;
    flex: 1;
    display: flex;
    justify-content: center;
    .content {
        min-width: 90%;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .video_list {
            display: flex;
            width: 100%;
            height: 582px;
            .list {
                background: rgba($color: #abacae, $alpha: 0.2);
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                width: 220px;
                overflow-x: hidden;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                .item_list {
                    height: 88px;
                    padding: 14px 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    //视频图片
                    .video_img {
                        margin-left: 14px;
                        width: 58px;
                        height: 58px;
                        border-radius: 2px;
                        object-fit: cover;
                    }
                    //视频名称
                    .video_name {
                        color: white;
                        flex: 1;
                        margin: 0 14px;
                        font-size: 14px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .item_list:hover {
                    background: rgba($color: #000000, $alpha: 0.4);
                }

                //选中的背景
                .select_bg {
                    background: rgba($color: #000000, $alpha: 0.4);
                }
            }
            .player {
                flex: 1;
                height: 100%;
                ::v-deep .video-player {
                    height: 100%;
                }
                ::v-deep .vjs-custom-skin > .video-js {
                    height: 100%;
                }
                ::v-deep .vjs-poster {
                    background-size: contain;
                }
            }
        }
    }

    .content ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
}
.study_video ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
</style>